import React from 'react';
import ServiceTemplate from './OurServices/ServiceTemplate';
import Img1 from '../Assets/Service/Group 844.png';
import Img2 from '../Assets/Service/Group 1020.png';
import Logo1 from '../Assets/Service/image 2.png';
import Logo2 from '../Assets/Service/image 3.png';
import YoutubeThumbnail from '../Assets/Service/Rectangle 31.png';

const AirFreight = () => {
  const data = {
    title: 'Air Freight',
    description:
      'Comprehensive Air Freight Solutions: Secure and Efficient Shipment Handling Worldwide',
    portraitImage: Img1,
    landscapeImage: Img2,
    landscapeImageDescription: (
      <>
        At EFS LOGISTICS, we specialize in handling a diverse range of shipments
        via air, including time-sensitive, hazardous, oversized, and perishable
        goods. With our unwavering commitment to care and efficiency, we ensure
        your shipments are handled with the utmost precision and delivered
        promptly to destinations worldwide. <br />
        <br />
        As a licensed air cargo carrier authorized by the General Authority of
        Civil Aviation, we maintain partnerships with major airlines, allowing
        us to offer reliable services at competitive rates. Our accreditation as
        an International Air Transport Association (IATA) member and licensed
        air cargo agent enables our partners to entrust their shipments to us on
        a back-to-back basis (MAWB/HAWB), providing them with added security and
        peace of mind. <br />
        <br />
        Rest assured that when you choose EFS LOGISTICS for your air freight
        needs, you benefit from our expertise, industry connections, and
        dedication to delivering exceptional service at every step of the
        journey.
      </>
    ),

    logos: [Logo1, Logo2],
    youtubeLink: '',
    youtubeThumbnailImage: '',
    buttonText: 'EFS Airfreight Service',
  };
  return (
    <ServiceTemplate
      title={data.title}
      description={data.description}
      portraitImage={data.portraitImage}
      landscapeImage={data.landscapeImage}
      landscapeImageDescription={data.landscapeImageDescription}
      logos={data.logos}
      youtubeLink={data.youtubeLink}
      youtubeThumbnailImage={data.youtubeThumbnailImage}
      buttonText={data.buttonText}
    />
  );
};

export function Head() {
  return <title>Air Freight | EFS Logistics KSA</title>;
}
export default AirFreight;
